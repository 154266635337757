.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .title {
        font-size: 2.5rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
        @media (min-width: 300px) {
            font-size: 3rem;
        }

        @media (min-width: 768px) {
            font-size: 4rem;
        }
    }

    .tag {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.5rem;
        font-weight: lighter;
        color: rgba(255, 255, 255, 0.8);

        @media (min-width: 768px) {
            font-size: 1.8rem;
        }
    }

    .social {
        margin: 0 1rem;

        :global {
            g.social-svg-icon:hover + g.social-svg-mask {
                fill: rgba(255, 255, 255, 0.3) !important;
            }

            g.social-svg-mask {
                fill: rgba(255, 255, 255, 0.1) !important;

                &:hover {
                    fill: rgba(255, 255, 255, 0.3) !important;
                }
            }
        }
    }
}
