@import 'scss/default_variables.scss';

.navBar {
    width: 100%;
    height: $nav-height;
    background-color: $nav-color;
    padding: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
