@import 'scss/default_variables.scss';

.siteContainer {
    height: 100vh !important;
    background-color: $bg-light-color;
    color: $app-light-color;

    .appContent {
        background-color: #222;
        color: #fff;
        height: 100vh;
    }
}
